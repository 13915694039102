import React, { PureComponent } from 'react';
import {
  SEO,
  LevelHero,
  Inspiration,
  LevelChallenge,
  LevelBenefits,
  LevelObjective
} from 'components';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Redirect } from '@reach/router';
import star from 'images/Levels/star.svg';
import level7Icon from 'images/Shared/LevelIcons/WhiteStroke/level_07_icon.svg';

class Level7 extends PureComponent {
  state = {
    componentHasMounted: false
  };

  componentDidMount() {
    this.setState({
      componentHasMounted: true
    });
  }

  render() {
    const { data, viewportWidth, user, game, selectedLevel } = this.props;
    const { levelData, emptyStage } = data;
    const {
      benefits,
      challenge,
      duration,
      levelPageSummary,
      levelVideoCode,
      objective,
      scoring,
      title,
      subTitle
    } = levelData.childMarkdownRemark.frontmatter;
    let { tools } = levelData.childMarkdownRemark.frontmatter;
    const {
      componentHasMounted
    } = this.state;
    if (game && game.uniqueDocs) {
      game.uniqueDocs.forEach((uniqueDocs) => {
        if (uniqueDocs.level === selectedLevel) {
          tools = {
            list: tools.list,
            link: uniqueDocs.link
          };
        }
      });
    }
    return user ? (
      <Wrapper>
        <SEO pageSpecificTitle="Level 7" pageSpecificDescription={levelPageSummary} />
        <LevelHero
          level={7}
          title={title}
          subTitle={subTitle}
          levelPageSummary={levelPageSummary}
          levelIcon={level7Icon}
          levelVideoCode={levelVideoCode}
        />
        <LevelObjective objective={objective} />
        <LevelChallenge
          challenge={challenge}
          duration={duration}
          tools={tools}
          reduceToolsFontSize
        />
        <Overview>
          <div>
            <LightGreenBox>
              <img src={star} alt="star" />
            </LightGreenBox>
            <p>
              <span>Notes</span> — Points will be awarded for both the quality of the ideas and the
              manner of the delivery, so a command of your chosen webcon tools is essential.
            </p>
          </div>
          <p
            style={{
              fontSize: '1.5rem',
              lineHeight: '1.333em',
              maxWidth: '926px'
            }}>
            <b>This is live TV. Here are some tips:</b>
            <br />
            <br />
            <br />
          </p>
          <div>
            <GreenBox>Tip 1</GreenBox>
            <p>Preparation is everything.</p>
          </div>
          <div>
            <GreenBox>Tip 2</GreenBox>
            <p>Rehearsals are essential.</p>
          </div>
          <div>
            <GreenBox>Tip 3</GreenBox>
            <p>A picture paints a thousand words.</p>
          </div>
          <div>
            <GreenBox>Tip 4</GreenBox>
            <p>Creativity that is congruent with the material could make the difference.</p>
          </div>
          <div>
            <GreenBox>Tip 5</GreenBox>
            <p>Brevity is the soul of successful presenting.</p>
          </div>
          <div>
            <GreenBox>Tip 6</GreenBox>
            <p>Your connectivity and camera work could make or break the presentation!</p>
          </div>
        </Overview>
        <InnerWrapper>
          <LevelBenefits
            benefits={benefits}
            level={selectedLevel}
            objective={objective}
            viewportWidth={viewportWidth}
            scoring={scoring}
          />
        </InnerWrapper>
        <Inspiration
          img={emptyStage}
          author="Elvis, Shakespeare (Hybrid!)"
          quote="The world’s a stage and each of us must play our part"
          quoteLargeFontSize="3.825rem"
          maxWidth="39.625rem"
          textWrapperTop="6em"
          viewportWidth={viewportWidth}
        />
      </Wrapper>
    ) : (
      componentHasMounted && <Redirect to="/" noThrow />
    );
  }
}

const Wrapper = styled.section``;

const InnerWrapper = styled.div`
  max-width: 90em;
  margin: 0 9.1%;
`;

const Overview = styled.div`
  background-color: rgba(239, 237, 228, 0.3);
  padding: 0 9.027777777777777% 2.625rem;
  display: flex;
  flex-direction: column;

  > div {
    align-items: flex-start;
    display: inline-flex;
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 2.75em;

    > p {
      margin-left: 0.833em;
      position: relative;
      top: 0.325em;
      font-weight: 400;
      line-height: 1.333em;
      max-width: 874px;

      > span {
        font-weight: 700;
      }

      > span:nth-of-type(2) {
        margin-top: 1em;
      }

      > span:not(:first-of-type) {
        display: block;
        color: var(--cx-dark-green);
      }
    }

    ul {
      margin-top: 0.75em;
      line-height: 1.333em;

      li {
        display: flex;
        margin-bottom: 0.75em;

        span {
          margin-right: 1.5em;
        }
      }
    }
  }
`;

const GreenBox = styled.span`
  background-color: var(--cx-dark-green);
  color: #fff;
  font-size: 1.5rem;
  height: 1.917em;
  width: 4.167em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.125em;
  position: relative;
  bottom: 0.02em;
  flex-shrink: 0;
  font-weight: 600;
`;

const LightGreenBox = styled.span`
  align-items: center;
  background-color: var(--cx-light-green);
  border-radius: 0.125em;
  bottom: 0.02em;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: 600;
  height: 1.917em;
  justify-content: center;
  position: relative;
  width: 1.917em;
`;

export const Level7Query = graphql`
  {
    levelData: file(sourceInstanceName: { eq: "levelData" }, relativePath: { eq: "level_07.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          subTitle
          levelPageSummary
          levelVideoCode
          challenge {
            heading
            detail
          }
          objective
          benefits {
            forPlayers
            forTheCampaign
          }
          duration {
            time
            detail
          }
          scoring {
            challenge {
              points
              detail
            }
            teamGame {
              points
              detail
            }
          }
          tools {
            list
            link
          }
        }
      }
    }
    emptyStage: file(relativePath: { eq: "Shared/Inspiration/empty_stage.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 634, maxHeight: 620) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default Level7;
